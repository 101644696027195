import React from 'react';

const Organisation = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="vuesax_outline_like-tag" data-name="vuesax/outline/like-tag" transform="translate(-428 -252)">
        <g id="like-tag">
          <path id="Vector" d="M5.2,11.241H3.22a2.279,2.279,0,0,1-1.52-.53L.16,9.521l.92-1.19,1.61,1.25a.974.974,0,0,0,.53.15H5.2a.84.84,0,0,0,.73-.52l1.26-3.85a.319.319,0,0,0,.01-.27.348.348,0,0,0-.28-.1H4.84a1.3,1.3,0,0,1-.97-.44,1.332,1.332,0,0,1-.3-1.04l.26-1.67a.3.3,0,0,0-.17-.32.325.325,0,0,0-.28.07L1.25,4.761,0,3.921,2.13.751a1.768,1.768,0,0,1,3.18,1.36L5.1,3.481H6.92a1.8,1.8,0,0,1,1.51.74,1.774,1.774,0,0,1,.19,1.62L7.38,9.621A2.324,2.324,0,0,1,5.2,11.241Z" transform="translate(437.03 256.869)" fill="CurrentColor" />
          <path id="Vector-2" data-name="Vector" d="M2.31,8.57H1.79A1.6,1.6,0,0,1,0,6.83V1.74A1.6,1.6,0,0,1,1.79,0h.52A1.6,1.6,0,0,1,4.1,1.74V6.83A1.6,1.6,0,0,1,2.31,8.57ZM1.53,7.06c.03,0,.11.01.26.01h.52a.987.987,0,0,0,.28-.03c0-.02.01-.08.01-.21V1.74a.71.71,0,0,0-.02-.22c-.02,0-.1-.01-.27-.01H1.79a.987.987,0,0,0-.28.03c0,.02-.01.08-.01.21V6.84a.86.86,0,0,0,.02.23A.01.01,0,0,1,1.53,7.06Z" transform="translate(434.25 258.97)" fill="CurrentColor" />
          <path id="Vector-3" data-name="Vector" d="M9.75,21.49a2.749,2.749,0,0,1-1.94-.8L6.1,19a2.256,2.256,0,0,0-1.59-.65H3.75A3.739,3.739,0,0,1,0,14.63V3.72A3.739,3.739,0,0,1,3.75,0h12A3.739,3.739,0,0,1,19.5,3.72V14.63a3.739,3.739,0,0,1-3.75,3.72h-.76A2.238,2.238,0,0,0,13.4,19l-1.71,1.69A2.749,2.749,0,0,1,9.75,21.49Zm-6-20A2.241,2.241,0,0,0,1.5,3.71V14.62a2.234,2.234,0,0,0,2.25,2.22h.76a3.754,3.754,0,0,1,2.65,1.09l1.71,1.69a1.268,1.268,0,0,0,1.77,0l1.71-1.69A3.754,3.754,0,0,1,15,16.84h.75A2.241,2.241,0,0,0,18,14.62V3.71a2.234,2.234,0,0,0-2.25-2.22Z" transform="translate(430.25 253.26)" fill="CurrentColor" />
          <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(428 252)" fill="none" opacity="0" />
        </g>
      </g>
    </svg>

  );
};

export default Organisation;
